export default {
  _name: 'light',
  _appearance: 'light',

  background: '#F9FAFC',
  border: '#EFF1F7',
  overlay: '#FFFFFF',
  content: '#212B36',
  contentSecondary: '#637381',

  surface: '#FFFFFF',
  surfaceContent: '#212B36',
  surfaceContentSecondary: '#637381',
  surfaceIcon: '#8FA4B5',
  surfaceUnder: '#F6F9FC',
  surfaceOpened: '#ABBECF',
  surfaceSelected: '#F9FAFC',
  surfaceHighlight: '#F9FAFC',
  surfacePressed: '#F9FAFC',
  surfaceInteractive: '#FFFFFF',

  feedbackSurface: '#F9FAFC',
  feedbackSurfaceContent: '#212B36',
  feedbackSurfaceContentSecondary: '#637381',

  warning: '#F5A623',
  warningSurface: '#FFF1DA',
  warningSurfaceContent: '#C7871E',

  info: '#08BEE5',
  infoSurface: '#EBFAFD',
  infoSurfaceContent: '#0792AF',

  help: '#7C80F2',
  helpContent: '#FFFFFF',
  helpSurface: '#FFFFFF',
  helpSurfaceContent: '#212B36',

  // negative to be removed
  negative: '#FF6969',
  negativeContent: '#FFFFFF',
  negativeSurface: '#FFE8E8',
  negativeSurfaceContent: '#CA4141',

  // positive to be removed
  positive: '#2CC68F',
  positiveContent: '#FFFFFF',
  positiveSurface: '#D8FFF1',
  positiveSurfaceContent: '#1B8962',

  // primary
  primary: '#00C2FF',
  primaryEnd: '#01E8F7',
  primaryContent: '#FFFFFF',
  primaryHover: '#66DAFF',
  primaryHoverEnd: '#01E8F7',
  primaryPressed: '#00ACE2',
  primaryPressedEnd: '#02DFED',

  // secondary
  secondary: '#FFFFFF',
  secondaryContent: '#00C2FF',
  secondaryHover: '#FFFFFF',
  secondaryPressed: '#F6F9FC',

  badge: '#EBFAFD',
  badgeContent: '#212B36',
  badgePressed: '#F9FAFC',

  tagIdentifier: '#EDE6FF',
  tagIdentifierContent: '#4D22DF',

  tagNew: '#D8FFF1',
  tagNewContent: '#2CC68F',

  tagIndicator: '#EBFAFD',
  tagIndicatorContent: '#08BEE5',

  tagActivity: '#08BEE5',
  tagActivityContent: '#FFFFFF',

  hint: '#7483AB',
  link: '#3E7BF6',
  focus: '#3E7BF6',
  selected: '#00C2FF',
  selectedContent: '#FFFFFF',
  selectedDisabled: '#C4CDD5',

  disabled: '#D2D9EE',
  disabledContent: '#7483AB',
  disabledIcon: '#B4C1E4',

  control: '#F9FAFC',
  controlBorder: '#DDE4E9',
  controlBorderPressed: '#ABBECF',
  controlDisabled: '#F6F9FC',
  controlSurface: '#F9FAFC',
  controlUnder: '#DDE4E9',

  accent: '#01C6FF',
  accentStart: '#32fff5',
  accentEnd: '#08BEE5',
  accentContent: '#FFFFFF',

  floating: '#30404F',
  floatingContent: '#FFFFFF',

  green: '#3CCE6E',
  yellow: '#FFB53A',
  red: '#FF6A60',
  blue: '#3E7BF6',
  // new black color
  black: '#20232C',

  brown: '#876559',
  purple: '#B8C2F6',
}
