export const GU = 8
export const RADIUS = 8
export const RADII = {
  tiny: 6,
  small: GU,
  medium: 12,
  large: 2 * GU,
}
export const SPACING = {
  small: GU,
  medium: 2 * GU,
  large: 3 * GU,
  xLarge: 4 * GU,
  uLarge: 5 * GU,
  mLarge: 6 * GU,
  tLarge: 7 * GU,
}
